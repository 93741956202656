import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import FacebookIcon from '../images/social/facebook.png';
import InstagramIcon from '../images/social/instagram.png';
import VimeoIcon from '../images/social/vimeo.png';
import BranchSvg from '../images/BranchIllustration.svg';


const Footer = () => {
    return <>
    <img src={BranchSvg} className="branch-illustration max-w-xs md:max-w-3xl ml-auto mb-16 mt-32" />
    <div className="bg-tb-dark   px-6 md:px-16" style={{height: "200px"}} >
        <div className="border-b-2 border-white py-4 md:py-6 " />
        <div className=" mt-8 block md:grid grid-cols-3  flex-wrap flex justify-between  md:justify-end">
        <Link to="/" className="">
            <StaticImage src="../images/logo.svg" alt="The branch logo" className="w-40 md:w-48 "  />
           {/* <span class="font-semibold text-md tracking-tight">The Branch</span>*/}
        </Link>
        <div className="mb-4 text-gray-500">
            <div className="flex pb-4 justify-center w-full mt-3 md:mt-0">
                <a href="https://vimeo.com/user134995821" target="_blank" rel="noreferrer">
                    <img className="mr-4 cursor-pointer" style={{height: "24px"}} src={VimeoIcon} />
                </a>
                <a href="https://www.instagram.com/the_branch_as/" target="_blank" rel="noreferrer">
                    <img className="mr-4 cursor-pointer" style={{height: "24px"}} src={InstagramIcon} />
                </a>
                <a href="https://www.facebook.com/The-Branch-SB-122220119776317/" target="_blank" rel="noreferrer">
                    <img className="cursor-pointer" style={{height: "24px"}} src={FacebookIcon}/>
                </a>
            </div>
           <p className="hidden md:block text-center">© 2021 The Branch AS. All Rights Reserved</p>
        </div>
        <p className="md:hidden text-center text-tb-gray w-full">© 2021 The Branch AS. All Rights Reserved</p>
       
    </div></div></>
}

export default Footer;