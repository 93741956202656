import React, {useEffect} from 'react';
import Footer from './Footer';
import Header from './Header';

function Layout({children, id}) {
    return (
      <>
      <Header />
      <div>
        {children}
        <Footer />
  </div>
  </>);
}

export default Layout;