import React, {useEffect} from 'react';
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions";
import Layout from '../components/Layout';

const LayoutIndex = ({ location, children }) => {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scroll = window.scrollY;
     
      if(scroll > 10) {
        document.getElementById("header").classList.add("shadow-sm");
      } else {
        document.getElementById("header").classList.remove("shadow-sm");
      }
    })
  }, [])
  return (
    <Layout>
    <TransitionProvider
     location={location}
     enter={{
      opacity: 0,
      transform: "translate3d(0,20vh,0) scale3d(1, 1, 1) rotate(0deg)",
      config: {
        mass: 1,
        tension: 210,
        friction: 20,
        clamp: true
      }
    }}
     >
      <TransitionViews>
        {children}
      </TransitionViews>
    </TransitionProvider>
    </Layout>

  );
};

export default LayoutIndex;