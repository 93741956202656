import React, { useState } from 'react';
import '../styles/menu.css';

const Menu = ({isOpen, setOpen}) => {

  return <div className={`menu-burger md:hidden ${isOpen ? 'fold' :  ''}`} onClick={() => setOpen(!isOpen)}>
      <div className="bar1"></div>
      <div className="bar2"></div>
  </div>
}

export default Menu;